// src/components/Contact.js
import React from "react";
import Links from '../components/Links';
import ContactForm from '../components/ContactForm';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Stack from '@mui/material/Stack';
export default function ContactPage(props) {

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `Lou_Mengfan_Resume.pdf`;
    link.href = "https://drive.google.com/uc?export=download&id=1lZpztxhUHdb9oZhmJyHKX4dY1MeePiDw";
    link.click();
  }
  return (
    <Box
    style={{overflow: 'auto'}}
    m={0} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiTextField-root': { m: 1, width: '60ch' },
      }}>
    <Box
    style={{overflow: 'auto'}}
    m={0} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .MuiTextField-root': { m: 1, width: '60ch' },
        }}>
        <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <div className="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
                  <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 className="title-font font-semibold text-white tracking-widest text-xs">
                      EMAIL
                    </h2>
                    <a className="text-indigo-400 leading-relaxed">
                      loumengfan@gmail.com
                    </a>
                    <h2 className="title-font font-semibold text-white tracking-widest text-xs mt-4">
                      PHONE
                    </h2>
                    <p className="leading-relaxed">+1-734-709-0971</p>
                    <h2 className="title-font font-semibold text-white tracking-widest text-xs mt-4">
                      RESUME
                    </h2>
                    <Button variant="contained" onClick={onDownload} endIcon={<FileDownloadIcon />}>
                      Download
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <br/>
          <Links></Links>
          <br/>
        <ContactForm backend = {props.backend}></ContactForm>
        </Box>
      </Box>
  );}
import React, { useState, useEffect, useMemo } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignIn from '../pages/SignInPage';
import PublicPage from '../pages/PublicPage';
import ProtectedPage from '../pages/ProtectedPage';
import ClashParsePage from '../pages/ClashParsePage';
import ForbiddenPage from '../pages/ForbiddenPage';
import IFCViewerPage from '../pages/IFCViewerPage';
// import OBJViewerPage from '../pages/OBJViewerPage';
import BackendTestPage from '../pages/BackendTestPage';
import ContactPage from '../pages/ContactPage';
import NotionPage from '../pages/NotionPage';
import NotionMainPage from '../pages/NotionMainPage';
import NotionAboutPage from '../pages/NotionAboutPage';
import SearchResultPage from '../pages/SearchResultPage';
import TagResultPage from '../pages/TagResultPage';
import Logout from '../components/google-logout';
import firebase from '../_services/firebase';
import PMIS_Survey from '../components/pmis_survey';
import {Loading} from '../components/Loading';
import { Page404 } from 'src/components/Page404';


function RequireAuth(props) {
  if (!props.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login"/>;
  }
  return props.children;
}

function HandleLogin(props) {
  if (!props.user) {
    return <SignIn/>;
  }
  return <Logout user = {props.user}/>
}



export default function AppRoutes(props) {
  // report user data 
  const [currentUser, setUser] = useState(null);
  const [pages, setPages] = useState([]);
  // const [content, setContent] = useState({block: ""});
  const [notionBackend, setNotionBackend] = useState("");
  const [loading, setLoading] = useState(true);
  // const [notionMain, setNotionMain] = useState();
  useEffect(() => {
    const loadData = async () => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      props.recordUser(user)})
      
    let notionServer = process.env.REACT_APP_NOTION_DEV_BACKEND
    if (process.env.NODE_ENV !== "development"){
      notionServer = process.env.REACT_APP_NOTION_PRODUCTION_BACKEND
    }
    setNotionBackend(notionServer)
    fetch(notionServer + `/notion/pages`,
    {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({'Page-Number': process.env.REACT_APP_NOTION_APP_MAIN_PAGE,
    'Space-Number': null}),
    }).then(res => res.json()).then(data => {
      setPages(data)});
      setLoading((loading) => !loading);
    }
    
    loadData();
  }, []);

  if (loading) {
    return <Loading/>
  }
  else{
// Get Notion Main Page
  return (
      <Routes>
          <Route exact path="/login" element={<HandleLogin user = {currentUser}/>}/>
          <Route exact path="/public" element={<PublicPage />} />
          <Route exact path="/pmis_survey" element={<PMIS_Survey />} />
          <Route exact path="/forbidden" element={<ForbiddenPage />} />
          <Route exact path="/ifc_viewer" element={<IFCViewerPage/>} />
          {/* <Route exact path="/obj_viewer" element={<OBJViewerPage/>} /> */}
          <Route exact path="/" element={<NotionMainPage darkMode = {props.darkMode} notionServer = {notionBackend} page = {process.env.REACT_APP_NOTION_COVER_PAGE}/>}/>
          <Route exact path="/blog" element={<NotionPage darkMode = {props.darkMode} notionServer = {notionBackend} page = {process.env.REACT_APP_NOTION_APP_MAIN_PAGE}/>}/>
          <Route exact path="/about" element={<NotionAboutPage darkMode = {props.darkMode} notionServer = {notionBackend} page = {process.env.REACT_APP_NOTION_ABOUT_PAGE}/>}/>
          {pages.map((r) => {
             return <Route exact path={r.replaceAll('-', '')} key ={r} element = {<NotionPage darkMode = {props.darkMode} notionServer = {notionBackend} page = {r}/>}/>})}
          <Route exact path="/search_result/:id" element={<SearchResultPage notionServer = {notionBackend}/>}/>
          <Route exact path="/tag/:id" element={<TagResultPage notionServer = {notionBackend}/>}/>
          <Route exact path="/test" element={<BackendTestPage user={currentUser} backend={props.flaskbackend}/>} />
          <Route exact path="/contact" element={<ContactPage backend={props.flaskbackend}/>} />
          <Route exact path="/protected" element={              
            <RequireAuth user={currentUser} backend={props.flaskbackend} children = {<ProtectedPage user={currentUser} backend={props.flaskbackend}/>}>
            </RequireAuth>} />
          <Route exact path="/clash_parser" element={              
            <RequireAuth user={currentUser} backend={props.flaskbackend} children = {<ClashParsePage user={currentUser} backend={props.flaskbackend}/>}>
            </RequireAuth>} />
          {/* <Route path = "*" element={<Page404/>} /> */}
      </Routes>
  );}
}
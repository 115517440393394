import {app,  auth } from '../../_services/firebase'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {writeUserData, getUserData} from '../../_utils/user-data';
import { useState, useEffect } from 'react';
import { getDatabase, ref, set, child, get, onValue } from "firebase/database";
import userImage from './user_image.jpg' 

export function MediaControlCard() {
  const theme = useTheme();
}
const Logout = ({ user }) => {
  const [userData, setUserData] = useState("");

  let logout = (response) => {
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
  };
  
  useEffect(() => {
    let userDataTemp = ""
    const db = getDatabase(app);
    const starCountRef = ref(db, 'users/' + user.uid);
    onValue(starCountRef, (snapshot) => {
    userDataTemp = snapshot.val();
    setUserData(userDataTemp)
    })
  }, []);

  return (
    <div className="home">
      <Container component="main" maxWidth="sm">
      <h1>Hello, <span></span>{userData?.displayName}</h1>
      <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {userData?.displayName}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Email: {userData?.email}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Role: {userData?.category}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
        </Box>
      </Box >
      <CardMedia
        component="img"
        sx={{ width: 270 }}
        image={userImage}
        alt={userImage}
      />
    </Card>
      <Box
        m={2} pt={1}
          sx={{

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      <Button variant="contained" onClick={() => auth.signOut()} >Sign out</Button>
      </Box>
      </Container>
    </div>
  )
}

export default Logout;
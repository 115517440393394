import * as React from 'react';
import {
    Link
  } from "react-router-dom";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { auth } from '../_services/firebase'
import { Button } from '@mui/material';

// const settings = ['Profile', 'Account', 'Dashboard'];
const settings = ['Profile'];
const UserMenu = (props:any) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenUserMenu = (event: any) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    if (props.user == null){
        return ( <Button component={Link} to={"/login"}>Login</Button>)
    }
    else{
        return (
            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={props.user.photoURL} />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu} component={Link} to={"/login"}>
                    <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
                <MenuItem key="Logout" onClick={() => auth.signOut()}>
                <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                </Menu>
            </Box>
        );
    }
    
}
export default UserMenu;
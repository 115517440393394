import React from 'react';
import { SocialIcon,  } from 'react-social-icons';
import Stack from '@mui/material/Stack';

const Links = () => {

  document.title = "Digital Automata";

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
    <SocialIcon url="https://www.linkedin.com/in/mengfanlou/" target="_blank" />
    <SocialIcon url="https://github.com/lmengfan/" target="_blank" bgColor ='#66BAB7'/>
    <SocialIcon url="mailto:loumengfan@gmail.com" target="_blank" bgColor = "#E87A90"/>
    </Stack>
  )
}

export default Links;
import "react-notion-x/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import React, { useState, useEffect } from 'react';
// import { NotionRenderer } from "react-notion";
import { NotionRenderer } from 'react-notion-x'
import Box from '@mui/material/Box';
import dynamic from 'next/dynamic'
import { ContentCopySharp } from "@mui/icons-material";
import {Loading} from '../components/Loading';
// import { NotionAPI } from 'notion-client'

// const notion = new NotionAPI()

// const recordMap = notion.getPage('067dd719a912471ea9a3ac10710e7fdf')


const Code = dynamic(() =>
  import('react-notion-x/build/third-party/code').then((m) => m.Code)
)
const Collection = dynamic(() =>
  import('react-notion-x/build/third-party/collection').then(
    (m) => m.Collection
  )
)
const Equation = dynamic(() =>
  import('react-notion-x/build/third-party/equation').then((m) => m.Equation)
)
const Pdf = dynamic(
  () => import('react-notion-x/build/third-party/pdf').then((m) => m.Pdf),
  {
    ssr: false
  }
)
const Modal = dynamic(
  () => import('react-notion-x/build/third-party/modal').then((m) => m.Modal),
  {
    ssr: false
  }
)



const NotionMainPage = (props) => {
  const [content, setContent] = useState({block: ""});
  //const [loading, setLoading] = useState(true);
  
  useEffect(() => {

  const loadData = () => {
    fetch(props.notionServer + `/notion/page`,
    {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
    },
    // body: JSON.stringify({a: 1, b: 2})
    body: JSON.stringify({'Page-Number': props.page}),
    }).then(res => res.json()).then(data => {
      setContent(data)});}
    loadData();
  }, []);
    // If page is in loading state, display 
    // loading message. Modify it as per your 
    // requirement.
  // if (loading) {
  //     return <Loading/>
  // }
    
  return(
    <NotionRenderer
    recordMap={content}
    fullPage={true}
    darkMode={props.darkMode}
    components={{
      Code,
      Collection,
      Equation,
      Pdf,
      Modal,
    }}/>
  )
}

export default NotionMainPage
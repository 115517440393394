// import './App.css';
import { IfcViewerAPI } from 'web-ifc-viewer';
import { Backdrop, CircularProgress, IconButton } from '@mui/material';
import {getName, getAll, newSubsetOfType, categories} from '../_utils/ifc'
import React from 'react';
import Dropzone from 'react-dropzone';
import BcfDialog from './BcfDialog';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
//Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import CropIcon from '@mui/icons-material/Crop';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {           IFCSITE, 
    IFCSLAB,
    IFCBEAM,
    IFCBEARING,
    IFCBUILDINGELEMENTPROXY,
    IFCCHIMNEY,
    IFCCOLUMN,
    IFCCOVERING,
    IFCCURTAINWALL,
    IFCDEEPFOUNDATION,
    IFCDOOR,
    IFCFOOTING,
    IFCMEMBER,
    IFCPLATE,
    IFCRAILING,
    IFCRAMP,
    IFCRAMPFLIGHT,
    IFCROOF,
    IFCSHADINGDEVICE,
    IFCSTAIR,
    IFCSTAIRFLIGHT,
    IFCWALL,
    IFCWINDOW,
    IFCFURNISHINGELEMENT,
    IFCANNOTATION,
    IFCBUILDINGELEMENTPART,
    IFCBUILDINGSTOREY,
    IFCDISTRIBUTIONCONTROLELEMENT,
    IFCDISTRIBUTIONELEMENT,
    IFCDISTRIBUTIONFLOWELEMENT,
    IFCELEMENTASSEMBLY,
    IFCENERGYCONVERSIONDEVICE,
    IFCFLOWCONTROLLER,
    IFCFLOWFITTING,
    IFCFLOWMOVINGDEVICE,
    IFCFLOWSEGMENT,
    IFCFLOWSTORAGEDEVICE,
    IFCFLOWTERMINAL,
    IFCFLOWTREATMENTDEVICE,
    IFCOPENINGELEMENT,
    IFCPILE,
    IFCREINFORCINGBAR,
    IFCREINFORCINGMESH,
    IFCSPACE, } from "web-ifc";
import { AmbientLight, AxesHelper, DirectionalLight, GridHelper, PerspectiveCamera, Scene, WebGLRenderer } from "three";


// const getData = (tree) => {
//     for (const [name, value] of Object.entries(tree)) {
//         console.log(`${name}: ${value}`);
//         }
//   };


const map = {"a": 1, "b": 2, "c": 3};
const mapped = Object.entries(map).map(([k,v]) => `${k}_${v}`);
console.log(mapped);

const getTreeItemsFromData = treeItems => {
return Object.entries(treeItems).map(([name, value]) => {
    let children = undefined;
    // console.log(`${name}: ${value}`);
    if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
    )
    {
        try
        {
            children = getTreeItemsFromData(value);
        }
        catch{
    
        }
        return (
            <TreeItem
                key={name}
                nodeId={name}
                label={name}
                children={children}
            />
            );
    }
    else if( 
    Array.isArray(value)&&
    value !== null){
        let subchild = undefined;
        let values = [];
        value.map(item => {
            try
            {
                subchild = getTreeItemsFromData(item);
                //console.log(subchild)
            }
            catch{
        
            }
            values.push(subchild)
        })
        return (
            <TreeItem
                key={name}
                nodeId={name}
                label={name}
                children={values}
            />
            );
    }
    else{
        return (
        <TreeItem
            key={`${name}: ${value}`}
            nodeId={`${name}: ${value}`}
            label={`${name}: ${value}`}
        />
        );
    }
});
};


class IFCViewer extends React.Component {

    categories = [
        IFCSITE, 
        IFCSLAB,
        IFCBEAM,
        IFCBEARING,
        IFCBUILDINGELEMENTPROXY,
        IFCCHIMNEY,
        IFCCOLUMN,
        IFCCOVERING,
        IFCCURTAINWALL,
        IFCDEEPFOUNDATION,
        IFCDOOR,
        IFCFOOTING,
        IFCMEMBER,
        IFCPLATE,
        IFCRAILING,
        IFCRAMP,
        IFCRAMPFLIGHT,
        IFCROOF,
        IFCSHADINGDEVICE,
        IFCSTAIR,
        IFCSTAIRFLIGHT,
        IFCWALL,
        IFCWINDOW,
        IFCFURNISHINGELEMENT,
        IFCANNOTATION,
        IFCBUILDINGELEMENTPART,
        IFCBUILDINGSTOREY,
        IFCDISTRIBUTIONCONTROLELEMENT,
        IFCDISTRIBUTIONELEMENT,
        IFCDISTRIBUTIONFLOWELEMENT,
        IFCELEMENTASSEMBLY,
        IFCENERGYCONVERSIONDEVICE,
        IFCFLOWCONTROLLER,
        IFCFLOWFITTING,
        IFCFLOWMOVINGDEVICE,
        IFCFLOWSEGMENT,
        IFCFLOWSTORAGEDEVICE,
        IFCFLOWTERMINAL,
        IFCFLOWTREATMENTDEVICE,
        IFCOPENINGELEMENT,
        IFCPILE,
        IFCREINFORCINGBAR,
        IFCREINFORCINGMESH,
        IFCSPACE,
    ];

    state = {
        bcfDialogOpen: false,
        loaded: false,
        loading_ifc: false,
        properties:"",
        checkedmark: true
    };

    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef();
    }



    componentDidMount() {
        
        const container = document.getElementById('viewer-container');
        const viewer = new IfcViewerAPI({container});
        viewer.axes.setAxes();
        viewer.grid.setGrid();
        viewer.clipper.active = true;
        viewer.IFC.setWasmPath('../../');
        this.viewer = viewer;
        window.onmousemove = viewer.prepickIfcItem;
        window.onmousemove = () => viewer.IFC.selector.prePickIfcItem()
        window.onclick = async () => {
            const {modelID, id} = await viewer.IFC.selector.pickIfcItem(true);
              const props = await viewer.IFC.getProperties(modelID, id, true, false);
              this.setState({
                ...this.state,
                properties: props
            });
          }
        console.log(0)
    }

    onDrop = async (files) => { 
        this.setState({ loading_ifc: true })
        await this.viewer.IFC.loadIfc(files[0], true);
        
        let scene = this.viewer.context.getScene()
        scene.clear()
        const lightColor = 0xffffff;

        const ambientLight = new AmbientLight(lightColor, 0.5);
        scene.add(ambientLight);

        const directionalLight = new DirectionalLight(lightColor, 1);
        directionalLight.position.set(0, 10, 0);
        directionalLight.target.position.set(-5, 0, 0);
        scene.add(directionalLight);
        scene.add(directionalLight.target);
        
        const axes = new AxesHelper();
        axes.material.depthTest = false;
        axes.renderOrder = 1;
        scene.add(axes);

        this.setState({ loaded: true, loading_ifc: false })
        this.setupAllCategories(this.categories)
        //console.log(this.viewer.IFC.loader.ifcManager)
        this.viewer.context.getScene()
    };

    handleToggleClipping = () => {
        this.viewer.clipper.active = !this.viewer.clipper.active;
    };

    handleClickOpen = () => {
        this.dropzoneRef.current.open();
    };

    handleOpenBcfDialog = () => {
        this.setState({
            ...this.state,
            bcfDialogOpen: true
        });
    };

    handleCloseBcfDialog = () => {
        this.setState({
            ...this.state,
            bcfDialogOpen: false
        });
    };

    handleOpenViewpoint = (viewpoint) => {
        this.viewer.currentViewpoint = viewpoint;
    };
    subsets = {};

    setupCheckBox = async(category) => {
        const name = getName(category);
        const checkBox = document.getElementById(name);
        console.log(name)
        //console.log(this.subsets)
        checkBox.addEventListener("change", async(event) => {
            const checked = event.target.checked;
            const subset = this.subsets[category];
            //console.log(getName(category))
            //console.log(this.subsets)
            //console.log(subset.parent.id)
            const scene = this.viewer.context.getScene()
            scene.autoUpdate = true
            // if (checked){ 
            //     scene.add(subset);
            // }
            // else {
            //     subset.removeFromParent();
            // }
            if (checked) scene.add(subset);
            //if (checked) scene.remove(subset);
            else{
                const manager = this.viewer.IFC.loader.ifcManager;
                // let walls = await manager.getAllItemsOfType(0, IFCCURTAINWALL, false);
                // console.log(walls)
                // scene.remove()
                // manager.removeSubset(subset)
                subset.removeFromParent()
                //scene.remove(subset)
                //scene.removeFromParent(subset)
                //this.viewer.IFC.loader.ifcManager.removeFromSubset(subset.parent.id, walls);
                //this.viewer.IFC.loader.ifcManager.removeSubset(subset.parent.id);
                //this.viewer.IFC.loader.ifcManager.removeSubset(subset.parent.id, subset);
                //console.log(this.viewer.IFC.loader.ifcManager.subsets);
            } 
        });
    }

    setupCategory = async(category) => {
        const scene = this.viewer.context.getScene()
        const sub = await newSubsetOfType(this.viewer.IFC.loader,scene,category);
        if(sub != null){
            this.subsets[category] = await newSubsetOfType(this.viewer.IFC.loader,scene,category);
        }
        scene.add(this.subsets[category])
        await this.setupCheckBox(category);
    }

    setupAllCategories = async (categories) => {
    //const allCategories = Object.values(categories);
    const allCategories = categories;
    //console.log(allCategories.length)
        for (let i = 0; i < allCategories.length; i++) {
            const category = allCategories[i];
            await this.setupCategory(category);
        }
    }

    render() {
        return (
        <Box              
        sx={{
            display:'flex',
            flexDirection: 'row',
           
        }}>
            <Box
            sx={{ 
                flexDirection: 'column',
                flexGrow: 1
            }}>
              <BcfDialog
                open={this.state.bcfDialogOpen}
                onClose={this.handleCloseBcfDialog}
                onOpenViewpoint={this.handleOpenViewpoint}
              />
              <div style={{ display: 'flex', flexDirection: 'row', height: '90vh' }}>
                  <aside style={{ width: 50 }}>
                      <IconButton onClick={this.handleClickOpen}>
                          <FolderOpenOutlinedIcon />
                      </IconButton>
                      <IconButton onClick={this.handleToggleClipping}>
                          <CropIcon />
                      </IconButton>
                     <IconButton onClick={this.handleOpenBcfDialog}>
                          <FeedbackOutlinedIcon />
                      </IconButton>
                  </aside>
                  <Dropzone ref={this.dropzoneRef} onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                        </div>
                      )}
                  </Dropzone>
                  <div style={{ flex: '1 1 auto', minWidth: 0 }}>
                      <div id='viewer-container' style={{ position: 'relative', height: '100%', width: '100%' }} />
                  </div>
              </div>
              <Backdrop
                style={{
                    zIndex: 300,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center"
                }}
                open={this.state.loading_ifc}
              >
                  <CircularProgress/>
              </Backdrop>
              </Box>
              <Box
              ml={2} 
                sx={{ 
                    flexDirection: 'column',
                    width:300,
                    display: "flex",
                    overflow: 'auto',
                }}>
                <Box
                sx={{ 
                    width:300,
                    height:500,
                    flexDirection: 'column',
                    display: "flex",
                    overflow: 'auto',
                    borderColor: 'primary.main',
                    border: 2
                }}>
                <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                >
                {getTreeItemsFromData(this.state.properties)}
                </TreeView>
            </Box>

            <Box
                mt={1} 
                pl={1}
                sx={{ 
                    flexDirection: 'column',
                    width:300,
                    height:340,
                    display: "flex",
                    overflow: 'auto',
                    borderColor: 'primary.main',
                    border: 2,
                    flexGrow: 1,
                }}>
            {/* <Autocomplete
                id="search"
                search
                options={this.categories.map((r) => getName(r))}
                renderInput={(params) => <TextField {...params} label="search" />}
            /> */}
            <FormGroup>
            {this.categories.map((r) => {
             return <FormControlLabel control={<Checkbox defaultChecked id={getName(r)} color="secondary"/>} label={getName(r)} />})}
            </FormGroup>
            </Box>
            </Box>
          </Box>
        );
    }
}
 
export default IFCViewer;
import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default class Contact extends Component {
    
    state = {
      name: "",
      message: "",
      email: "",
      subject: "",
      sent: false,
      buttonText: "",
      emailError: false,
    };
    resetForm = () => {
        this.setState({
          name: "",
          message: "",
          email: "",
          subject: "",
          buttonText: "",
        });
    
        setTimeout(() => {
          this.setState({ sent: false });
        }, 3000);
      };
    
      handleChangeEmail(e) {
        if (
          !e.target.value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.setState({
            email: e.target.value,
          });
          this.setState({ emailError: true });
    
          if (this.state.email === "") {
            // check if the input is empty
            this.setState({ emailError: false });
          }
        } else {
          this.setState({ email: e.target.value, emailError: false });
        }
      }
    
      formSubmit = async (e) => {
        e.preventDefault();
        this.setState({
          buttonText: "...sending",
        });
    
        let data = {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          subject: this.state.subject,
        };
        
        try {
          console.log(data);
          const rawResponse = await fetch(`${this.props.backend}/email_message`, {
            method: 'POST',
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          } );
          console.log(rawResponse.status)
          if(rawResponse.status === 200){
            this.setState({
              name: "",
              message: "",
              email: "",
              subject: "",
              buttonText: "sent",
            });
          }
          const content = await rawResponse.json()
        } catch (error) {
          console.log(JSON.stringify(data));
          console.log(error);
        }
      };
  // Functions
    render() {
      return (
        <form className="contact-form" onSubmit={(e) => this.formSubmit(e)}>
        <TextField
          id="outlined-basic"
          placeholder="Enter Subject"
          label="Subject"
          variant="outlined"
          value={this.state.subject}
          onChange={(e) => this.setState({ subject: e.target.value })}
          required
        />
        <br />
        <TextField
          id="standard-multiline-flexible"
          label="Message"
          placeholder="Enter Message"
          variant="outlined"
          multiline
          rows={5}
          value={this.state.message}
          onChange={(e) => this.setState({ message: e.target.value })}
          required
          type="text"
        />
        <br />

        <TextField
          id="outlined-basic"
          placeholder="Enter your name"
          label="Name"
          variant="outlined"
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
          required
          type="text"
        />
        <br />

        <TextField
          id="outlined-basic"
          label="Email"
          placeholder="Enter email address"
          variant="outlined"
          value={this.state.email}
          onChange={(e) => this.handleChangeEmail(e)}
          error={this.state.emailError}
          required
          type="email"
        />
        <br/>
        <br/>
        <div className="button--container">
          {/* <button type="submit" className="button button-primary">
            {this.state.buttonText}
          </button> */}
          <Box sx={{ flexDirection: 'row' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                  ml: 1,
                  mr: 2,
                  display: { xs: 'flex' },
                  flexGrow: 1,
                  fontWeight: 300,
                  letterSpacing: '.05rem',
                  textDecoration: 'none',
                }}
            >
              Submit
            </Button>
            <p>{this.state.buttonText}</p>
          </Box>
        </div>
      </form>
      );
    }
  }
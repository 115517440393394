import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import {Link} from "react-router-dom";
import Box from '@mui/material/Box';
import { purple, red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

export default function ImgMediaCard(props) {
  const navigate = useNavigate();
  return (
    <Card sx={{ maxWidth: 345, minWidth:300}}>
      <CardActionArea url={props.id}  component={Link} to={'/' + props.pageId}>
      <CardMedia
        component="img"
        alt="cover"
        height="160"
        image={props.image}
        sx={{
          color: 'secondary.main',
          fontWeight: 'bold',
          mx: 0.5,
          fontSize: 12,
        }}
      />
      <CardContent>
      <Typography gutterBottom variant="h5" component="div">
         {props.icon}
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
         {props.title}
        </Typography>

        <Typography variant="body2" color="text.primary"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 'bold',
                          mx: 0.5,
                          fontSize: 14,
                        }}>
          {props.summary}
        </Typography>
        <br></br>
        <Typography variant="body2" color="secondary.light"
                sx={{
                  fontWeight: 'bold',
                  mx: 0.5,
                  fontSize: 10,
                }}>
          Last Edited: {props.time}
        </Typography>

      </CardContent>
      </CardActionArea>
      <CardActions>
      <Box>
      {props.tags?.map((r) => {
            return <Button mx="auto" 
            key={r.id} 
            size="small" 
            color="success"
            onClick={() => {
              navigate('/search_result/' + r.name)
            }}>
              {r.name}
            </Button>})}
      </Box>
      </CardActions>
    </Card>
  );
}
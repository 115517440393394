import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getDatabase, ref, set, child, get, onValue } from "firebase/database";
import {app, auth} from '../_services/firebase';
import {writeUserData, getUserData} from '../_utils/user-data';

const BackendTestPage = (props:any) => {

  const [currentTime, setCurrentTime] = useState(0);
  useEffect(() => {
    fetch(`${props.backend}/time`, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    }).then(res => res.json()).then(data => {
      setCurrentTime(data.time);
    });
  }, []);

  const handleTestClick = (event:any) => {
    if (props.user != null){
      fetch(`${props.backend}/api/v1/time`, {
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "authorization": JSON.parse(JSON.stringify(props.user))["stsTokenManager"]["accessToken"]
         }
      }).then(res => res.json()).then(data => {
        console.log(data);
      });
      const user = auth.currentUser;
      //console.log(user)
      //writeUserData(app, user.uid, user.displayName, user.email, user.photoURL, "admin");
      //getUserData(app, user.uid)
    }
  };

  return (
    <Box
    m={2} pt={1}
      sx={{
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
    <p>The current time is {currentTime}.</p>
    <h3>This is a test page for backend access. Will show current time in console if authentication went through successfully</h3>
    <Button variant="contained" onClick={handleTestClick} >Test</Button>
    </Box>
    )
}

export default BackendTestPage;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBe9W16o4J1A2gkSsCclGnt2OPpPY7q9z8",
  authDomain: "flask-test-4cf16.firebaseapp.com",
  projectId: "flask-test-4cf16",
  databaseURL: "https://flask-test-4cf16-default-rtdb.firebaseio.com/",
  storageBucket: "flask-test-4cf16.appspot.com",
  messagingSenderId: "264473210823",
  appId: "1:264473210823:web:209b5ad47566094d8773e2",
  measurementId: "G-GE9BLT27E6"
};
// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => {auth.signInWithPopup(provider)};
export default firebase;
import {       
  IFCSITE, 
  IFCSLAB,
  IFCBEAM,
  IFCBEARING,
  IFCBUILDINGELEMENTPROXY,
  IFCCHIMNEY,
  IFCCOLUMN,
  IFCCOVERING,
  IFCCURTAINWALL,
  IFCDEEPFOUNDATION,
  IFCDOOR,
  IFCFOOTING,
  IFCMEMBER,
  IFCPLATE,
  IFCRAILING,
  IFCRAMP,
  IFCRAMPFLIGHT,
  IFCROOF,
  IFCSHADINGDEVICE,
  IFCSTAIR,
  IFCSTAIRFLIGHT,
  IFCWALL,
  IFCWINDOW,
  IFCFURNISHINGELEMENT,
  IFCANNOTATION,
  IFCBUILDINGELEMENTPART,
  IFCBUILDINGSTOREY,
  IFCDISTRIBUTIONCONTROLELEMENT,
  IFCDISTRIBUTIONELEMENT,
  IFCDISTRIBUTIONFLOWELEMENT,
  IFCELEMENTASSEMBLY,
  IFCENERGYCONVERSIONDEVICE,
  IFCFLOWCONTROLLER,
  IFCFLOWFITTING,
  IFCFLOWMOVINGDEVICE,
  IFCFLOWSEGMENT,
  IFCFLOWSTORAGEDEVICE,
  IFCFLOWTERMINAL,
  IFCFLOWTREATMENTDEVICE,
  IFCOPENINGELEMENT,
  IFCPILE,
  IFCREINFORCINGBAR,
  IFCREINFORCINGMESH,
  IFCSPACE,} from "web-ifc";
import { MeshBasicMaterial} from "three";
// List of categories names
export const categories = {
  IFCSITE, 
  IFCSLAB,
  IFCBEAM,
  IFCBEARING,
  IFCBUILDINGELEMENTPROXY,
  IFCCHIMNEY,
  IFCCOLUMN,
  IFCCOVERING,
  IFCCURTAINWALL,
  IFCDEEPFOUNDATION,
  IFCDOOR,
  IFCFOOTING,
  IFCMEMBER,
  IFCPLATE,
  IFCRAILING,
  IFCRAMP,
  IFCRAMPFLIGHT,
  IFCROOF,
  IFCSHADINGDEVICE,
  IFCSTAIR,
  IFCSTAIRFLIGHT,
  IFCWALL,
  IFCWINDOW,
  IFCFURNISHINGELEMENT,
  IFCANNOTATION,
  IFCBUILDINGELEMENTPART,
  IFCBUILDINGSTOREY,
  IFCDISTRIBUTIONCONTROLELEMENT,
  IFCDISTRIBUTIONELEMENT,
  IFCDISTRIBUTIONFLOWELEMENT,
  IFCELEMENTASSEMBLY,
  IFCENERGYCONVERSIONDEVICE,
  IFCFLOWCONTROLLER,
  IFCFLOWFITTING,
  IFCFLOWMOVINGDEVICE,
  IFCFLOWSEGMENT,
  IFCFLOWSTORAGEDEVICE,
  IFCFLOWTERMINAL,
  IFCFLOWTREATMENTDEVICE,
  IFCOPENINGELEMENT,
  IFCPILE,
  IFCREINFORCINGBAR,
  IFCREINFORCINGMESH,
  IFCSPACE,
};

// Gets the name of a category
export function getName(category) {
  const names = Object.keys(categories);
  return names.find((name) => categories[name] === category).slice(3);
}

// Gets the IDs of all the items of a specific category
export async function getAll(ifcLoader, category) {
    const manager = ifcLoader.ifcManager;
    const result = await manager.getAllItemsOfType(0, category, false);
    
    //console.log(result)
    return result
  }
  
// Creates a new subset containing all elements of a category
export async function newSubsetOfType(ifcLoader, scene, category) {
    const ids = await getAll(ifcLoader, category);
    if(ids.length === 0){
      return null
    }
    const material = new MeshBasicMaterial( { color: 0x00ff00 } );
    const result = ifcLoader.ifcManager.createSubset({
      modelID: 0,
      scene,
      ids,
      //material: material,
      removePrevious: false,
      customID: category.toString(),
    });
    //console.log(result)
    return result
  }
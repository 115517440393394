import { signInWithGoogle } from '../../_services/firebase';
import GoogleButton from 'react-google-button'
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const Login = (props) => {
  return (
    <div>
    <Container component="main" maxWidth="xs">
    <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <GoogleButton disabled = {props.disabled} className="button" 
          onClick={signInWithGoogle}
        />
      </Box>
      </Container>
      </div>
  )
}

export default Login;


import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import './App.css';
import AppRoutes from './AppRoutes.jsx';
import UserMenu from '../components/UserMenu';

// import Material UI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MainListItems from './listItems';
import ThemeToggleButton from '../components/ThemeButton';
import SearchBox from '../components/SearchBox';
import logo from './logo192.png' 
import Links from '../components/Links';

function Copyright(props: any) {
  return (
        <Box
        m={1}
      //margin
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Typography variant="body2" color="text.secondary" align="right" {...props}>
          {'Copyright © '}
          <small>
            Mengfan Lou
          </small>{' '}
          {new Date().getFullYear()}
          {'.'}
          <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
        </Typography>
        <Links/>
      </Box>
  );
}

const drawerWidth: number = 220;

interface AppBarProps  {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      boxSizing: 'border-box',
      
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      flexShrink: 0,
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

interface MainContentProps  {
  open?: boolean;
}

const MainContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MainContentProps>((open) => (!open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`
  }),
);

const mdTheme = createTheme({
   palette: {
    primary: {
      main: '#51A8DD',
    },
    secondary: {
      main: '#DB4D6D',
      light: '#e87a90',
      dark: '#9F353A',
    },
    background: {
      paper: '#fff',
    },
    error: {
      main: '#F75C2F',
      light: '#FB966E',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      main: '#009688',
      dark: '#009688',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#08192D',
    },
    secondary: {
      main: '#DB4D6D',
      light: '#e87a90',
      dark: '#9F353A',
    },
    error: {
      main: '#F75C2F',
      light: '#FB966E',
    },

  },
});

function App() {
  const [open, setOpen] = React.useState(false);
  const [theme, setTheme] = React.useState(darkTheme);
  const [backend, setBackend] = React.useState("");
  const [notionBackend, setNotionBackend] = React.useState("");
  const [modedark, setDarkmode] = React.useState(true);
  // const [notionBackend, setNotionBackend] = React.useState(null);

  useEffect(() => {
    let notionServer = process.env.REACT_APP_NOTION_DEV_BACKEND
    if (process.env.NODE_ENV !== "development"){
      notionServer = process.env.REACT_APP_NOTION_PRODUCTION_BACKEND
    }
    setNotionBackend(notionServer)  
    let server = process.env.REACT_APP_DEV_BACKEND
    if (process.env.NODE_ENV !== "development"){
      server = process.env.REACT_APP_PRODUCTION_BACKEND
    }
    setBackend(server)   
    });
    

  const handleThemeChange = (event:any) => {
    if(modedark === true){
      setTheme(mdTheme)
      setDarkmode(false)
    }
    else {
      setTheme(darkTheme)
      setDarkmode(true)
    }
  };
   
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //set user authentication token
  const [currentUser, setUser] = useState(null);
  const recordUserCallback = (childData: any) =>{
    setUser(childData)
  }


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" enableColorOnDark={true}  open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon/>
            </IconButton> */}
            {/* <AdbIcon/> */}
              <img width="30" src={logo} alt="Logo" />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  ml: 2,
                  mr: 2,
                  display: { xs: 'flex' },
                  flexGrow: 1,
                  fontSize: 20,
                  fontFamily: 'monospace',
                  fontWeight: 300,
                  letterSpacing: '.05rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}

              >
                DIGITAL AUTOMATA
              </Typography>

            {/* <Box sx={{ flexGrow: 1, display: {  md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                // onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box> */}
            <SearchBox notion = {notionBackend}></SearchBox>
            <Box sx={{ display: 'flex', flexGrow: 0, flexDirection: 'row',}}>
              <IconButton color="inherit">
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Box sx={{m: 1,}}>
              <ThemeToggleButton handleChange={handleThemeChange} mode={modedark}/>
              </Box>
              <Box sx={{m: 1,}}>
              <UserMenu user={currentUser}/>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}>
            {/* <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Digital Automata
            </Typography> */}

            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
            <MainListItems toggle = {toggleDrawer} open = {open}/>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            justifyContent: 'flex-start'
          }}
        >
          <Toolbar />
          <MainContent>
            <Container maxWidth = {false} sx={{ mt: 2, mb: 2 }}>
            <AppRoutes darkMode = {modedark} recordUser={recordUserCallback} flaskbackend={backend}/>
            <Copyright sx={{ pt: 4}} />
            </Container>
          </MainContent>
        </Box>
      </Box>
    </ThemeProvider>
    </div>
  );
}


const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//react-native
import JSSoup from 'jssoup'; 
import { ContactSupportOutlined } from '@mui/icons-material';
import CircularIntegration from '../components/CircularIntegration';
import CircularIndeterminate from '../components/CircularProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';

class ClashParsePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imageURL: '',
      fileState: 'Upload File',
      fileName: '',
      buttonDisable:true,
      loading: false
    };

    this.handleUploadImage = this.handleUploadImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    this.setState({fileName: event.target.value,
                    buttonDisable: false});
  }
  onDownload() {
    const link = document.createElement("a");
    link.download = `Sample_ClashMatrix.xlsx`;
    link.href = "https://drive.google.com/uc?export=download&id=1o3k3PbQ1QCleFJvsoRSgqMv3MG9l5rLO";
    link.click();
  }

  async handleUploadImage(ev) {
    ev.preventDefault();

    const data = new FormData();
    data.append('file', this.uploadInput.files[0]);
    //data.append('filename', this.fileName.value);
    this.setState({
      loading: true,
    });
    const rawResponse = await fetch(`${this.props.backend}/clash_parser`, {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "authorization": JSON.parse(JSON.stringify(this.props.user))["stsTokenManager"]["accessToken"]
      },
      body: data,
    });
    const content = await rawResponse.json()
    this.setState({
      loading: false,
    })
    let result = document.getElementById('outlined-multiline-static-result');
    result.value = content["message"]
  }

  render() {
    return (
      <div>
      <form onSubmit={this.handleUploadImage}>
        <div>
        <Box
        style={{overflow: 'auto'}}
        m={0} pt={1}
        component="div"
          sx={{
            display: 'inline',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiTextField-root': { m: 1, width: '180ch' },
          }}
        >
        <Button variant="contained" component="label"             
            sx={{
                ml: 3,
                mr: 2,
                flexGrow: 1,
                fontWeight: 300,
                letterSpacing: '.05rem',
                textDecoration: 'none',
              }}>
          {this.state.fileState}
          <input hidden onChange={this.handleChange} ref={(ref) => { this.uploadInput = ref; }} type="file" />
        </Button>
      </Box>
      <Box component="div" sx={{ display: 'inline' }}>{this.state.fileName}</Box>
        </div>
        <br/>
        <div>
          <LoadingButton
            disabled ={this.state.buttonDisable}          
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.handleClick}
            loading={this.state.loading}
            sx={{
                ml: 3,
                mr: 2,
                display: { xs: 'flex' },
                flexGrow: 1,
                fontWeight: 300,
                letterSpacing: '.05rem',
                textDecoration: 'none',
              }}
          >
          Create Xml
          </LoadingButton>
        </div>
      </form>
        <Box
        style={{overflow: 'auto'}}
        m={2} pt={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiTextField-root': { m: 1, width: '180ch' },
          }}
        >
        <TextField
          id="outlined-multiline-static-result"
          helperText="RESULT"
          multiline
          rows={20}
        />

        </Box>
        <Box component="div" sx={{ml: 3,
            mr: 2, display: 'inline' }}>Please upload en excel file and then click Create XML. Example of excel file can be downloaded below.</Box>
        <Box style={{overflow: 'auto'}}
        m={0} pt={1}
        component="div"
          sx={{
            display: 'inline',
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiTextField-root': { m: 1, width: '180ch' },
          }}>
          <Button variant="contained" m={5} pt={1} 
          onClick={this.onDownload} endIcon={<FileDownloadIcon />}
          sx={{
            ml: 3,
            mr: 2,
            display: { xs: 'flex' },
            flexGrow: 1,
            fontWeight: 300,
            letterSpacing: '.05rem',
            textDecoration: 'none',
          }}>
            Download Sample File
          </Button>
        </Box>
        
        </div>
    );
  }
}

export default ClashParsePage;

// src/components/Contact.js
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ImgMediaCard from '../components/ImgMediaCard';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';

function GetTitle(obj){
    if(obj.properties?.title?.title[0]?.text?.content) {
        return obj.properties?.title?.title[0]?.text?.content
    }
    else{
        return obj.properties?.Name?.title[0]?.plain_text
    }
}

function GetId(str){
    return str.replace(/-/g, '')
}


export default function SearchResultPage(props) {
    const [searchResult, setSearchResult] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const rawResponse = fetch(props.notionServer + `/notion/query_database`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({'keyword':id}),
        }).then(res => res.json())
        .then(res =>{setSearchResult(res['results'].filter((ele)=> ele.object === 'page'))})
        //.then(console.log(searchResult))
      }, [id]);

      // useEffect(() => {
      //   console.log(searchResult)
      // }, [searchResult]);

  return (
    <React.Fragment>
    <div>
        <h2 className="title-font font-semibold text-white tracking-widest text-xs">
        Search Result
        </h2>
        <a className="text-indigo-400 leading-relaxed">
        keyword: {id}, {searchResult.length} results
        </a>
    </div>
    <Box
    style={{overflow: 'auto'}}
    m={0} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiTextField-root': { m: 1, width: '60ch' },
      }}>
        <Grid container spacing={2} direction='row' style={{ width: '100%'}}>
            {searchResult.map((r) => {
            return <Grid key = {r.url} item xs>
                    <ImgMediaCard  key = {GetId(r.id.toString())} 
                    image = {r.cover?.external?.url? r.cover?.external?.url: r.cover?.file?.url} 
                    title = {GetTitle(r)}
                    summary = {r?.properties?.Summary?.rich_text[0]?.plain_text}
                    pageId = {GetId(r.id.toString())} 
                    time = {r?.last_edited_time}
                    tags = {r?.properties?.Tags?.multi_select}
                    icon = {r?.icon?.emoji}/>
                </Grid>})}
        </Grid>
        
      </Box>
    </React.Fragment>
  );}
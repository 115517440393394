import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';

export default function ThemeToggleButton(props:any) {
  if(props.mode){
    return (
      <ToggleButton
        value="dark"
        onChange={props.handleChange}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          bgcolor: 'background.paper',
          height: '40px',
          overflow: 'hidden',
          borderRadius: '6px',
          boxShadow: 1,
          fontWeight: 'bold',
        }}
      >
        <LightModeIcon />
      </ToggleButton>
    );
  }
  else{
    return (
      <ToggleButton
        value="light"
        onChange={props.handleChange}
        sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        color: 'background.paper',
        height: '40px',
        overflow: 'hidden',
        borderRadius: '6px',
        boxShadow: 1,
        fontWeight: 'bold',
      }}
      >
        <ModeNightIcon/>
        
      </ToggleButton>
    );
  }

    // <ToggleButtonGroup
    //   color="primary"
    //   value={props.alignment}
    //   exclusive
    //   onChange={props.handleChange}
    // >
    //   <ToggleButton value="light"      
    //   sx={{
    //     display: 'flex',
    //     flexDirection: { xs: 'column', md: 'row' },
    //     alignItems: 'center',
    //     bgcolor: 'background.paper',
    //     height: '40px',
    //     overflow: 'hidden',
    //     borderRadius: '6px',
    //     boxShadow: 1,
    //     fontWeight: 'bold',
    //   }}>Light</ToggleButton>
    //   <ToggleButton value="dark"
    //         sx={{
    //             display: 'flex',
    //             flexDirection: { xs: 'column', md: 'row' },
    //             alignItems: 'center',
    //             bgcolor: 'background.paper',
    //             overflow: 'hidden',
    //             height: '40px',
    //             borderRadius: '6px',
    //             boxShadow: 1,
    //             fontWeight: 'bold',
    //           }}>Dark</ToggleButton>
    // </ToggleButtonGroup>
//  );
}
import React from 'react';
import IFCViewer from '../components/IFCViewer';
// import IFC from '../components/IFC';

const IFCViewerPage = ({ user }) => {
  return (
    <IFCViewer/>
    // <IFC/>
  )
}

export default IFCViewerPage;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//react-native
import JSSoup from 'jssoup'; 
import { ContactSupportOutlined } from '@mui/icons-material';
import CircularIntegration from '../components/CircularIntegration';
import CircularIndeterminate from '../components/CircularProgress';

const ProtectedPage = (props) => {
  const [submit, setSubmit] = React.useState(false);
  const [translate, setTranslate] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [translateSuccess, setTransSuccess] = React.useState(false);
  const [content, setContent] = useState('<html><head>hello</head></html>');
  const showFile = async () => {
    setSubmit(true)
    if (window.File && window.FileReader && window.FileList && window.Blob) {
        
         var preview = document.getElementById('outlined-multiline-static-source');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

         if (file.type.match(textFile)) {
            reader.onload = function (event) {
               //preview.innerHTML = event.target.result;
               preview.value = event.target.result;
               
            }
         } else {
            preview.value = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
   setSubmit(false)
  }

  const postFile = async () => {
    setTranslate(true)
    var preview = document.getElementById('outlined-multiline-static-source');
    const rawResponse = await fetch(`${props.backend}/parser`,
      {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "authorization": JSON.parse(JSON.stringify(props.user))["stsTokenManager"]["accessToken"]
      },
      body: JSON.stringify({a: 1, b: preview.value})
    });
    const content = await rawResponse.json()
    let result = document.getElementById('outlined-multiline-static-result');
    result.value = content["message"]
    setTranslate(false)
    }

  return (
    <Box
    m={2} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
          <Box
    m={2} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
    {/* <CircularIntegration state={submit} success={submitSuccess} onClick={showFile}/> */}
    <Button variant="contained" component="label" onChange={showFile}>
      Upload File
      <input type="file" hidden />
    </Button>
    {submit && (<CircularIndeterminate/>)}
    </Box>
    <Box
    style={{overflow: 'auto'}}
    m={2} pt={1}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiTextField-root': { m: 1, width: '60ch' },
      }}
    >
      
    <TextField
      id="outlined-multiline-static-source"
      helperText="HTML"
      multiline
      rows={20}
    />
    <TextField
      id="outlined-multiline-static-result"
      helperText="RESULT"
      multiline
      rows={20}
    />
    </Box>
    <CircularIntegration state={translate} success={translateSuccess} onClick={postFile}></CircularIntegration>
    {/* <Button variant="contained" onClick={postFile}>Translate</Button> */}
  </Box>
    )
}

export default ProtectedPage;

import {Link} from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PollIcon from '@mui/icons-material/Poll';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import React, { useState, useEffect } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BookIcon from '@mui/icons-material/Book';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import AbcIcon from '@mui/icons-material/Abc';
import ApartmentIcon from '@mui/icons-material/Apartment';

export default function MainListItems(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const [parserOpen, setParserOpen] = React.useState(false);

  const parserHandleClick = () => {
    setParserOpen(!parserOpen);
  };

return(
  <List component="nav">
    <ListItemButton onClick={props.toggle}>
      <ListItemIcon>
          <IconButton
          // edge="start"
          edge= {props.open? "end": "start"}
          color="secondary"
          aria-label="open drawer"
          onClick={props.toggle}
          sx={{
            marginRight: '36px',
            // ...(props.open && { display: 'none' }),
          }}
        >
          {props.open? <ChevronLeftIcon />: <MenuIcon/>}
        </IconButton>
        
      </ListItemIcon>
    </ListItemButton>

    <ListItemButton component={Link} to={'/'}>
      <ListItemIcon>
        <HomeIcon/>
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>

    <ListItemButton component={Link} to={'/blog'}>
      <ListItemIcon>
        <BookIcon/>
      </ListItemIcon>
      <ListItemText primary="Blog" />
    </ListItemButton>

    <ListItemButton component={Link} to={"/pmis_survey"}>
      <ListItemIcon>
        <PollIcon />
      </ListItemIcon>
      <ListItemText primary="Survey" />
    </ListItemButton>

    {/* <ListItemButton component={Link} to={"/protected"}>
      <ListItemIcon> 
        <SubtitlesIcon />
      </ListItemIcon>
      <ListItemText primary="COBie Parser" />
    </ListItemButton> */}

    {/* <ListItemButton component={Link} to={"/login"}>
      <ListItemIcon>
        <LoginIcon />
      </ListItemIcon>
      <ListItemText primary="Login" />
    </ListItemButton> */}

    <ListItemButton onClick={parserHandleClick}>
          <ListItemIcon>
          <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText primary="Apps" />
          {parserOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={parserOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/protected"}>
            <ListItemIcon>
            <AbcIcon />
            </ListItemIcon>
            <ListItemText primary="COBie Parser" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to={"/clash_parser"}>
            <ListItemIcon>
            <SignalCellularConnectedNoInternet0BarIcon/>
            </ListItemIcon>
            <ListItemText primary="Clash Parser" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to={"/ifc_viewer"}>
            <ListItemIcon>
            <ApartmentIcon/>
            </ListItemIcon>
            <ListItemText primary="IFC Viewer" />
          </ListItemButton>
        </List>
      </Collapse>
  
      {/* <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="3D Viewer" />
          {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} component={Link} to={"/ifc_viewer"}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="IFC Viewer" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link} to={"/obj_viewer"}>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="OBJ Viewer" />
          </ListItemButton>
        </List>
      </Collapse> */}
    {/* <ListItemButton component={Link} to={"/test"}>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Test" />
    </ListItemButton> */}
    <Divider sx={{ my: 1 }} />
    {/* <ListSubheader component="div" inset>
      About
    </ListSubheader> */}
    <ListItemButton component={Link} to={"/about"}>
      <ListItemIcon>
        <InfoIcon />
      </ListItemIcon>
      <ListItemText primary="About" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/contact"}>
      <ListItemIcon>
        <ContactMailIcon />
      </ListItemIcon>
      <ListItemText primary="Contact" />
    </ListItemButton>
  </List>
);
}

